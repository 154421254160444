import name from './name'

const selectSliceData = state => state[name]

const selectTodos = state => selectSliceData(state).todos

const selectLogs = state => selectSliceData(state).logs

export {
  selectTodos,
  selectLogs
}
