import { configureStore } from '@reduxjs/toolkit'
import * as reducers from './index'

const reducer = Object.values(reducers).reduce((acc, current) => {
  acc[current.name] = current.reducer
  return acc
}, {})

export default configureStore({
  reducer
})
