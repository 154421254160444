
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

export default function createTheme (options) {
  const theme = createMuiTheme({
    palette: {
      type: options?.darkTheme ? 'dark' : 'light'
    },
    overrides: {
      MuiListItemText: {
        primary: {
          fontSize: '12px'
        }
      },
      MuiToolbar: {
        root: {
          backgroundColor: '#303030'
        }
      }
    }
  }, {
    palette: {
      primary: {
        main: '#FBC337'
      }
    }
  })

  return responsiveFontSizes(theme)
}
