import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { PrivateRoute } from '@emerald-works/react-auth'
import {
  HomePage,
  Clients
} from './pages'

const PrivateRoutePage = () => {
  return (
    <div>
      This is a private route sample page
    </div>
  )
}

const ApplicationRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/clients' element={<Clients />} />
      <PrivateRoute path='/private-route' element={<PrivateRoutePage />} />
    </Routes>
  )
}

export default ApplicationRoutes
