import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import API from '../../services/api'
import consts from '../../consts'

const fetch = new API({
  url: 'https://api.github.com'
})

// Store
export const simpleExample = createSlice({
  name,
  initialState: {
    searchQuery: '',
    isFetchingRepos: false,
    repos: [],
    totalReposForSearch: 0
  },
  reducers: {
    setSearchQuery: (state, { payload }) => {
      state.searchQuery = payload
    },
    setIsFetchingRepos: (state, { payload }) => {
      state.isFetchingRepos = payload
    },
    setRepos: (state, { payload }) => {
      state.repos = payload
    },
    setTotalReposForSearch: (state, { payload }) => {
      state.totalReposForSearch = payload
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setSearchQuery,
  setIsFetchingRepos,
  setRepos,
  setTotalReposForSearch
} = simpleExample.actions

// Async  Actions
export const fetchRepos = () => async (dispatcher, getState) => {
  dispatcher(setIsFetchingRepos(true))

  try {
    const state = getState()
    const query = state[name].searchQuery
    const result = await fetch.get(`/search/repositories?q=${encodeURI(query)}`)
    if (result.kind === consts.apiStatus.ok) {
      dispatcher(setTotalReposForSearch(result.data.total_count || 0))
      dispatcher(setRepos(result.data.items || []))
    }
  } catch (error) {
    console.log(error)
  }

  dispatcher(setIsFetchingRepos(false))
}

// Reducer
export default simpleExample.reducer
