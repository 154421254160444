import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const simpleExample = createSlice({
  name,
  initialState: {
    title: 'Initial Title for the simpleExample Slice'
  },
  reducers: {
    setTitle: (state, { payload }) => {
      state.title = payload
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setTitle
} = simpleExample.actions

// Reducer
export default simpleExample.reducer
