import React, { useContext } from 'react'
import { Typography, Toolbar, AppBar, Switch, FormControlLabel } from '@material-ui/core'
import { useStyles } from './style'
import { CustomThemeContext } from '../../contexts/theme'

export default function AppToolbar () {
  const classes = useStyles()
  const theme = useContext(CustomThemeContext)

  return (
    <AppBar className={classes.appBar} position='fixed'>
      <Toolbar>
        <div className={classes.leftContainer}>
          <img src='/logo.png' className={classes.logoIcon} alt='corvus logo' />
          <Typography variant='h6' className={classes.title}>
            CORVUS
          </Typography>
        </div>
        <div className={classes.centerContainer} />
        <div className={classes.rightContainer}>
          <FormControlLabel
            control={
              <Switch
                checked={theme.isDarkTheme}
                onChange={(value) => theme.setIsDarkTheme(!theme.isDarkTheme)}
              />
            }
            label='Dark Theme'
          />
        </div>
      </Toolbar>

    </AppBar>
  )
}
