import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const todoExample = createSlice({
  name,
  initialState: {
    todos: [],
    logs: []
  },
  reducers: {
    setTodos: (state, { payload }) => {
      const { todos } = payload
      const sortedTodos = todos.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      state.todos = sortedTodos
    },
    addTodo: (state, { payload }) => {
      const { todo } = payload
      state.todos = [todo, ...state.todos]
    },
    toggleTodo: (state, { payload }) => {
      const { todo } = payload
      const todoToToggle = state.todos.find(e => e.id === todo.id)
      if (todoToToggle) todoToToggle.done = todo.done
    },
    deleteTodo: (state, { payload }) => {
      const { id } = payload
      state.todos = state.todos.filter(todo => todo.id !== id)
    },
    addLog: (state, { payload }) => {
      const { action, payload: todo } = payload
      state.logs.push({
        date: new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false
        }).format(new Date()),
        action,
        payload: JSON.stringify(todo, null, 2)
      })
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setTodos,
  addTodo,
  addLog,
  toggleTodo,
  deleteTodo
} = todoExample.actions

// Reducer
export default todoExample.reducer
