import * as actions from './slice'

export const getTotal = {
  eventName: 'getTotalRealTime',
  subscribeOnInit: true,
  onSuccess: {
    action: actions.setTotal,
    redux: true
  }
}

export const increment = {
  eventName: 'incrementRealTime'
}

export const decrement = {
  eventName: 'decrementRealTime'
}

export const reset = {
  eventName: 'resetRealTime'
}

export const getTotalWithEffect = {
  eventName: 'getTotalRealTimeWithEffect',
  subscribeOnInit: true,
  onSuccess: {
    action: actions.setTotal,
    redux: true
  }
}

export const incrementWithEffect = {
  eventName: 'incrementRealTimeWithEffect'
}

export const decrementWithEffect = {
  eventName: 'decrementRealTimeWithEffect'
}

export const resetWithEffect = {
  eventName: 'resetRealTimeWithEffect'
}
