import * as actions from './slice'

// Event definitions are the same as the ones not using redux the only difference is that the events listeners are
// redux actions. For example the setTotal action is define in the Redux slice.
// Here is where you bind your event to redux actions. Once the data on the slices changes
// through EventBus lifecycle hook events. React will react to it and update the values.
export const getTotal = {
  eventName: 'getTotal',
  onSuccess: {
    action: actions.setTotal,
    redux: true
  }
}

export const increment = {
  eventName: 'increment',
  onSuccess: {
    action: actions.setTotal,
    redux: true
  }
}

export const decrement = {
  eventName: 'decrement',
  onSuccess: [
    {
      action: actions.setTotal,
      redux: true
    }
  ]
}

export const reset = {
  eventName: 'reset',
  onSuccess: [
    {
      action: actions.setTotal,
      redux: true
    }
  ]
}
