import psl from 'psl'

// returns an object with { subDomains, domain, topLevelDomains }
const domainSlices = () => {
  const parsed = psl.parse(window.location.hostname)

  return {
    ...parsed
  }
}

// return full address
const basedURL = () => {
  return window.location.host
}

// Domain without port number if present
const nakedBasedURL = () => {
  return window.location.hostname
}

export {
  domainSlices,
  basedURL,
  nakedBasedURL
}
