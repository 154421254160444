import * as DomainHelpers from '../helpers/domain'

const authSettings = () => {
  const baseUrl = DomainHelpers.basedURL()
  const cookieUrl = DomainHelpers.nakedBasedURL()

  return {
    region: process.env.REACT_APP_COGNITO_USER_POOL_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
      domain: cookieUrl,
      path: '/',
      expires: 365,
      secure: true
    },
    oauth: {
      domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid'
      ],
      redirectSignIn: `https://${baseUrl}/signin`,
      redirectSignOut: `https://${baseUrl}/signout`,
      responseType: 'token'
    }
  }
}

export {
  authSettings
}
