import React, { useState, createContext } from 'react'
import createTheme from '../theme'
import { ThemeProvider } from '@material-ui/core/styles'

const initialState = true

export const CustomThemeContext = createContext(initialState)

export function CustomThemeProvider ({ children }) {
  const [isDarkTheme, setIsDarkTheme] = useState(initialState)

  return (
    <CustomThemeContext.Provider value={{ isDarkTheme, setIsDarkTheme }}>
      <ThemeProvider theme={createTheme({ darkTheme: isDarkTheme })}>
        {children}
      </ThemeProvider>
    </CustomThemeContext.Provider>
  )
}
