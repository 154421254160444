import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const counterExample = createSlice({
  name,
  initialState: {
    total: 0
  },
  reducers: {
    setTotal: (state, { payload }) => {
      state.total = payload.total
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setTotal
} = counterExample.actions

// Reducer
export default counterExample.reducer
