import name from './name'

const selectSliceData = state => state[name]

const selectSearchQuery = state => selectSliceData(state).searchQuery
const selectRepos = state => selectSliceData(state).repos
const selectTotalRepos = state => selectSliceData(state).totalReposForSearch
const selectIsFetchingRepos = state => selectSliceData(state).isFetchingRepos

export {
  selectSearchQuery,
  selectRepos,
  selectTotalRepos,
  selectIsFetchingRepos
}
