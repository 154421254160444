import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const chatExample = createSlice({
  name,
  initialState: {
    messages: []
  },
  reducers: {
    setMessages: (state, { payload }) => {
      state.messages = payload.messages
    },
    addMessage: (state, { payload }) => {
      state.messages.push(payload.message)
    },
    simpleAction: (state, { payload }) => {
      console.log('oiooioi')
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setMessages,
  addMessage,
  simpleAction
} = chatExample.actions

// Reducer
export default chatExample.reducer
