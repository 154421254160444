import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useStyles } from './style'

const Clients = () => {
  const classes = useStyles()
  const [clients, setClients] = useState([])
  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await axios.get('https://rest-live-clone.dev.devsquad.cloud/clients')
        setClients(response.data)
      } catch (error) {
        console.error('Historias tristes: ', error)
      }
    }
    getClients()
  }, [])
  return (
    <div className={classes.clientsDisplay}>
      <h1>Dados do Cliente</h1>
      {clients.map((client) => (
        <>
          <p>ID: <span>{client.id}</span></p>
          <p>Nome: <span>{client.name}</span></p>
          <p>Moeda: <span>{client.currency}</span></p>
          <p>Ativo: <span>{client.is_active ? 'Sim' : 'Não'}</span></p>
          <p>Data de Criação: <span>{client.created_at}</span></p>
          <p>Data de Atualização: <span>{client.updated_at}</span></p>
        </>
      ))}
    </div>
  )
}

export default Clients
