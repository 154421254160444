import React from 'react'
import { useStyles } from './style'

const FormExample = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <p>Hello World</p>
    </div>
  )
}

export default FormExample
