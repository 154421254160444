export default {
  apiStatus: {
    cannotConnect: 'cannot-connect',
    timeout: 'timeout',
    server: 'server',
    unknown: 'unknown',
    unauthorized: 'unauthorized',
    forbidden: 'forbidden',
    notFound: 'not-found',
    rejected: 'rejected',
    CANCEL_ERROR: 'CANCEL_ERROR',
    ok: 'ok'
  },
  fetchingStatus: {
    pending: 'pending',
    ok: 'ok',
    idle: 'idle',
    error: 'error',
    done: 'done',
    dataError: 'dataError',
    searching: 'searching'
  }
}
